'use client';

import {
  InfiniteData,
  QueryClient,
  useIsMutating,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
  UseMutateAsyncFunction,
} from '@tanstack/react-query';
import { createChatTransformer, defaultPage, optimisticInfiniteQueryUpsert, request, stream } from '../util';
import {
  ChatResponse,
  Page_Message_ as MessagePage,
  Message,
  Page_ChatResponse_ as ChatHistory,
  ChatUpdateRequest,
} from '@portal/chat-sdk';
import useCsrfTokenHeader from '~/core/hooks/use-csrf-token-header';
import { ChatCreateRequest } from '../../../chat-sdk/src/client/types.gen';
import { v4 } from 'uuid';
import { useState } from 'react';
import { Paginated } from '../util';
import {
  whereAny,
  curry,
  mergeRight,
  mergeLeft,
  over,
  lensPath,
  append,
  lensProp,
  findIndex,
  mergeWithKey,
  any,
  equals,
  defaultTo,
  where,
  isNil,
  allPass,
  complement,
  replace,
  compose,
} from 'ramda';
import { isChunkOfType } from '../util';
import { AdditionalMutationOptions } from '../types';
import type { ToolChunk, ToolCallChunk, ToolStreamStartChunk } from '../util';
import { useRequest } from '../request';
import { useActiveAssistantId } from '~/lib/contexts/assistant';
import { ChatCompletionMessageToolCallParam, EventPayload } from '@portal/chat-sdk';
import { ExtendedInfiniteData, sortItems, useChatHistoryInfiniteQuery } from './queries';
import { addSeconds } from 'date-fns/fp';
import posthog from 'posthog-js';

type CreateChatOptions = Omit<
  UseMutationOptions<ExtendedChatResponse, Error, ChatCreateRequest>,
  'mutationKey' | 'mutationFn'
>;

interface ExpandedEvent extends EventPayload {
  progress?: number;
  sync?: number;
}

export type ExtendedChatResponse = ChatResponse & { isTemp?: boolean };

export interface ExpandedMessage extends Omit<Message, 'event'> {
  is_streaming?: boolean;
  is_waiting_for_first_chunk?: 'yes' | 'no';
  is_working?: boolean;
  has_tool_call?: boolean;
  tool_query?: string;
  tool_call?: ToolCallChunk;
  tool_result?: { text?: string };
  event?: ExpandedEvent | null | undefined;
}

const removeFilePrefix = replace(/^https:\/\/[^\/]+\/[^\/]+\/[^\/]+\/[^\/]+\//, '');
const mergeWithContent = mergeWithKey((key: string, left: ExpandedMessage, right: ExpandedMessage) => {
  if (key === 'content') {
    return `${right ?? ''}${left ?? ''}`;
  }
  return left || right;
});

const isMessageMatch = curry(function isMessageMatch(incoming: ExpandedMessage, current: ExpandedMessage) {
  return whereAny(
    {
      id: equals(incoming.id),
      event: allPass([
        complement(isNil),
        where({
          content: (value: string) => {
            return removeFilePrefix(value) === removeFilePrefix(incoming.event?.content ?? '');
          },
        }),
      ]),
    },
    current,
  );
});

const findMessageIndex = curry(function findMessageIndex(message: ExpandedMessage, data: readonly ExpandedMessage[]) {
  if (!data) return -1;
  return findIndex<ExpandedMessage>(isMessageMatch(message), data);
});

const findPageIndex = curry(function findPageIndex(
  message: ExpandedMessage,
  data: readonly Paginated<ExpandedMessage>[],
) {
  if (!data) return -1;
  return findIndex(
    where({
      items: any<ExpandedMessage>(isMessageMatch(message)),
    }),
    data,
  );
});

/**
 * In the case of an infinite query, it will have the following structure:
 * ```json
 * {
 *   "pages": [
 *     {
 *       "items": [Message, Message, Message]
 *     },
 *     {
 *       "items": [Message]
 *     }
 *   ]
 * }
 * ```
 * This means that we will have to find the item in the collection of pages
 * if it is not found, we will just append it to the last page
 * if it is found, we will have to upsert that item
 * @param message
 * @param client
 * @returns
 */
export async function addMessageToPage(message: ExpandedMessage | Message, client: QueryClient) {
  return client.setQueryData<
    InfiniteData<MessagePage, number>,
    readonly ['chat', string, 'messages'],
    InfiniteData<MessagePage, number>
  >(['chat', message.chat_id, 'messages'], (queryData) => {
    const res = over(
      lensProp('pages'),
      (pages) => {
        const index = findPageIndex(message, pages);
        const pageIndex = index === -1 ? pages.length - 1 : index;
        const page = pages?.[pageIndex]?.items ?? [];
        const itemIndex = findMessageIndex(message, page);
        if (itemIndex < 0) {
          return over(lensPath([pageIndex, 'items']), compose(append(message), defaultTo([])), pages);
        } else {
          return over(
            lensPath([pageIndex, 'items', itemIndex]),
            (current) => mergeWithContent(message, current),
            pages,
          );
        }
      },
      defaultPage(queryData),
    );
    return sortItems(res);
  });
}

export type ChatCreateRequestType = UseMutateAsyncFunction<ChatResponse, Error, ChatCreateRequest, void>;

/**
 * Creates a new chat
 */
export function useCreateChatMutation({ onSuccess, onMutate, ...options }: CreateChatOptions = {}) {
  const client = useQueryClient();

  const csrfToken = useCsrfTokenHeader();
  const headers = new Headers(csrfToken);
  headers.set('Content-Type', 'application/x-www-form-urlencoded');

  const assistantId = useActiveAssistantId();

  return useMutation({
    ...options,
    mutationKey: ['chat', 'create'],
    async mutationFn(variables: ChatCreateRequest) {
      return request<ChatResponse>(
        {
          pathname: `/api/v2/chat`,
        },
        {
          method: 'POST',
          headers,
          body: JSON.stringify(variables),
        },
      );
    },
    // We will want to optimistically add this chat into the cache
    onMutate(variables) {
      client.setQueryData<InfiniteData<ChatHistory, number>>(['chat', 'history', assistantId], (previous) => {
        return optimisticInfiniteQueryUpsert(
          mergeRight,
          {
            id: v4(),
            isTemp: true,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          },
          defaultPage(previous as InfiniteData<ChatHistory, number>),
        ) as InfiniteData<ChatHistory, number>;
      });
      onMutate?.(variables);
    },
    async onSuccess(data, variables, context) {
      // On success, we have to make sure the chat has its pages loaded
      await client.ensureQueryData({
        queryKey: ['chat', data.id, 'messages'],
        async queryFn() {
          const items = await request<MessagePage>({
            pathname: `/api/v2/chat/${data.id}/messages`,
            query: { page: 1, size: 50 },
          });
          return { pages: [items], pageParams: [1] } satisfies InfiniteData<MessagePage>;
        },
      });
      // Invalidate the chat history query
      client.invalidateQueries({
        queryKey: ['chat', 'history', assistantId],
      });
      return onSuccess?.(data, variables, context);
    },
  });
}

interface SendMessageVariables {
  prompt: string;
  chat_id: string;
  assistantId: string;
  attachments?: string[] | null;
  isNewChat?: boolean;
}

type Context = void;

type SendMessageMutation = UseMutationResult<Context, Error, SendMessageVariables>;
type SendMessageOptions = Omit<UseMutationOptions<Context, Error, SendMessageVariables>, 'mutationKey' | 'mutationFn'>;

type Evolver<T> = {
  [K in keyof T]: (value: T[K]) => T[K];
};

export const createMessage = defaultTo<Omit<ExpandedMessage, 'chat_id'>>({
  id: v4(),
  content: '',
  type: 'human' as const,
  created_at: new Date(),
  is_streaming: false,
  is_waiting_for_first_chunk: 'yes',
  is_working: false,
  has_tool_call: false,
  event: null,
});

/**
 * Sends a message to the given chat
 *
 * Note that messages will stream in slowly from the backend.
 * When a message is streaming, it will update the query cache of the messages for that chat
 *
 * @param id
 * @param assistantId
 * @returns
 * @example
 * ```typescriptreact
 * function SendMessageForm({ assistantId }) {
 *   const { mutateAsync: createChat } = useCreateChatMutation();
 *   const { mutateAsync: sendMessage } = useSendMessageMutation();
 *
 *   async function action() {
 *     const chat = await createChat({ assistant_id: assistantId });
 *     const response = await sendMessage({ prompt: 'Hello', chat_id: chat.id });
 *   }
 *
 *   return (
 *     <form action={action}>
 *       <input type="text" name="prompt" />
 *       <button type="submit">Send</button>
 *     </form>
 *   )
 * }
 * ```
 */
export function useSendMessageMutation(
  assistantId: string,
  { onSuccess, ...options }: SendMessageOptions = {},
): SendMessageMutation {
  const [messageId, setMessageId] = useState(v4());
  const [responseId, setResponseId] = useState(v4());
  const queryClient = useQueryClient();

  const csrfToken = useCsrfTokenHeader();
  const headers = new Headers(csrfToken);
  headers.set('Content-Type', 'application/x-www-form-urlencoded');

  const chatTitleMutation = useChatTitleMutation();

  return useMutation({
    ...options,
    mutationKey: ['chat', 'message'],
    async mutationFn({ chat_id: chatId, assistantId, prompt = '', attachments }: SendMessageVariables) {
      const isTemp = typeof chatId === 'string' && chatId.startsWith('temp-');

      const isUploadingAttachments = Array.isArray(attachments) && attachments.length > 0;

      const now = new Date();
      // This will return a temporary id for this request
      const userMessage = createMessage<ExpandedMessage>({
        id: messageId,
        content: prompt,
        type: 'human',
        chat_id: chatId,
        event: null,
        created_at: now,
      });

      addMessageToPage(userMessage, queryClient);

      const responseMessage = createMessage<ExpandedMessage>({
        id: responseId,
        type: 'ai',
        content: '',
        chat_id: chatId,
        is_streaming: true,
        is_waiting_for_first_chunk: 'yes',
        event: null,
        // This is just to ensure it's sorted after the user message
        created_at: addSeconds(2, now),
      });

      if (isTemp) {
        addMessageToPage(responseMessage, queryClient);
        return;
      }

      // Here we will have to optimistically add a message as the file upload
      if (isUploadingAttachments) {
        for (const attachment of attachments) {
          addMessageToPage(
            createMessage({
              id: v4(),
              chat_id: chatId,
              content: '',
              type: 'human',
              event: {
                type: 'file_upload' as const,
                content: attachment,
              },
            }),
            queryClient,
          );
        }
      }

      // if chat attachment not need to add message to paget to show loading indicator
      if (!isUploadingAttachments) {
        addMessageToPage(responseMessage, queryClient);
      }

      const response = await stream(
        {
          pathname: `/api/v2/chat/${chatId}/messages`,
        },
        {
          method: 'POST',
          body: JSON.stringify({
            prompt,
            attachments,
          }),
          headers,
        },
      );

      // if chat attachment is more than one no need to stream
      if (isUploadingAttachments) {
        return;
      }

      let toolCallMessage = {
        id: v4(),
        type: 'tool',
        content: '',
        chat_id: chatId,
        created_at: new Date(),
        is_waiting_for_first_chunk: 'no',
        event: null,
      } satisfies ExpandedMessage;


      // if the first message is tool_stream_start we can use existing response message
      let firstChunkType: string = '';

      const result = await response.body
        .pipeThrough(new TextDecoderStream('utf-8'))
        .pipeThrough(createChatTransformer())
        .pipeTo(
          new WritableStream({
            write(chunk) {
              let updatedMessage = { ...responseMessage, is_waiting_for_first_chunk: 'no' };

              if (!firstChunkType) {
                firstChunkType = chunk.type;
              }

              if ((chunk as unknown as string) === '') return;

              if (isChunkOfType<ToolStreamStartChunk>(chunk, 'tool_call_stream_start')) {
                if (firstChunkType !== 'tool_call_stream_start') {
                  responseMessage.id = v4();
                  responseMessage.created_at = new Date();
                  toolCallMessage.id = v4();
                }

                addMessageToPage(
                  {
                    ...toolCallMessage,
                    is_working: true,
                  },
                  queryClient,
                );
              } else if (isChunkOfType<ToolCallChunk>(chunk, 'tool_call')) {
                addMessageToPage(
                  {
                    ...updatedMessage,
                    is_working: true,
                    additional_kwargs: {
                      tool_calls: chunk.tool_calls as ChatCompletionMessageToolCallParam[],
                    },
                  },
                  queryClient,
                );
              } else if (isChunkOfType<ToolChunk>(chunk, 'tool')) {
                addMessageToPage(
                  {
                    id: v4(),
                    type: 'tool',
                    content: '',
                    chat_id: chatId,
                    created_at: new Date(),
                  },
                  queryClient,
                );

                addMessageToPage(
                  {
                    ...updatedMessage,
                    tool_result: chunk,
                    is_working: true,
                  },
                  queryClient,
                );
              } else {
                addMessageToPage(
                  {
                    ...updatedMessage,
                    content: chunk.text,
                    // We are now done with any tool calls
                    is_working: false,
                  },
                  queryClient,
                );
              }
            },
          }),
        );
      await chatTitleMutation.mutate({ chatId, assistantId });
      return result;
    },
    async onSuccess(data, variables, context) {
      setMessageId(v4());
      setResponseId(v4());

      await queryClient.invalidateQueries({
        queryKey: ['chat', variables.chat_id, 'messages'],
      });

      if (!variables.chat_id.startsWith('temp-')) {
        posthog.capture('Message sent', {
          chat_id: variables.chat_id,
          assistant_id: assistantId,
          created_at: new Date().toISOString(),
        });
      }
      return await onSuccess?.(data, variables, context);
    },
    async onError(error, variables) {
      posthog.capture('Chat response error', {
        chat_id: variables.chat_id,
        assistant_id: assistantId,
        error: error.message,
        timestamp: new Date().toISOString(),
      });
    },
  });
}

/**
 * Returns if a message is currently being sent.
 * @returns
 */
export function useIsMessageSending() {
  const messagesSending = useIsMutating({
    mutationKey: ['chat', 'message'],
  });
  return messagesSending > 0;
}

/**
 * Updates the chat history
 */

export function useChatHistoryItemMutation({
  id,
  onSuccess,
}: {
  id?: string;
  onSuccess?: (chat: ChatResponse) => void;
}) {
  const csrfToken = useCsrfTokenHeader();
  const queryClient = useQueryClient();

  const assistantId = useActiveAssistantId();

  return useMutation({
    mutationKey: ['chat', 'history', 'edit', id],
    mutationFn: async (data: ChatUpdateRequest) => {
      const response = await fetch(process.env.NEXT_PUBLIC_SITE_URL + '/api/v2/chat/' + id, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...csrfToken,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        console.error(JSON.stringify(response, undefined, 2));
        throw new Error('Failed to patch chat history');
      }
      return response.json() as Promise<ChatResponse & { id: string }>;
    },
    onSuccess: (updatedHistoryItem) => {
      onSuccess?.(updatedHistoryItem);
      queryClient.invalidateQueries({
        queryKey: ['chat', updatedHistoryItem.id],
      });
      return queryClient.setQueryData<InfiniteData<Paginated<ChatResponse>, number>>(
        ['chat', 'history', assistantId],
        (previous) => {
          const mergeHistory = mergeLeft(updatedHistoryItem) as (a: ChatResponse) => ChatResponse;
          return optimisticInfiniteQueryUpsert(mergeHistory, updatedHistoryItem, previous);
        },
      );
    },
  });
}

const findChatHistoryById = curry((id: string, history: ChatResponse[]): ChatResponse | undefined => {
  const res = history.find((chat) => chat.id === id);
  return res;
});

const getChatById = (queryClient: QueryClient, chatId: string, assistantId: string) => {
  const history = queryClient.getQueryData<InfiniteData<ChatHistory, number>>(['chat', 'history', assistantId]);

  const chatHistory = findChatHistoryById(chatId, history?.pages.flatMap((page) => page.items) || []);
  if (chatHistory) {
    return chatHistory;
  }
  return undefined;
};

type UpdateTitleResponse = null | {
  icon: string;
  title: string;
};

interface UpdateTitleVariables {
  chatId: string;
  assistantId: string;
}

export function useChatTitleMutation({
  onSuccess,
  ...options
}: AdditionalMutationOptions<UpdateTitleResponse, UpdateTitleVariables, ['chat', 'title']> = {}) {
  const csrfToken = useCsrfTokenHeader();
  const queryClient = useQueryClient();

  const assistantId = useActiveAssistantId();

  return useMutation({
    ...options,
    mutationKey: ['chat', 'title'],
    async mutationFn({ chatId, assistantId }) {
      const chat = getChatById(queryClient, chatId as string, assistantId as string);
      if (!chat) {
        return null;
      }

      const needsChatTitleUpdate = !chat.title || chat.title === 'New chat';
      if (!needsChatTitleUpdate) {
        return null;
      }

      const response = await fetch(process.env.NEXT_PUBLIC_SITE_URL + '/api/v2/chat/' + chatId + '/title', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...csrfToken,
        },
      });

      if (!response.ok) {
        console.error(JSON.stringify(response, undefined, 2));
        throw new Error('Failed to patch chat history');
      }

      return response.json() as Promise<{
        icon: string;
        title: string;
      }>;
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ['chat', 'history', assistantId],
      });
      queryClient.invalidateQueries({
        queryKey: ['chat', variables.chatId],
        exact: true,
      });

      return onSuccess?.(data, variables, context);
    },
  });
}

export function useDeleteChatMutation({ onSuccess }: { onSuccess?: () => void }) {
  const csrfToken = useCsrfTokenHeader();
  const queryClient = useQueryClient();

  const assistantId = useActiveAssistantId();

  return useMutation({
    mutationKey: ['chat', 'delete'],
    mutationFn: async ({ id }: { id?: string }) => {
      if (!id) {
        throw new Error('No id provided');
      }

      const response = await fetch(process.env.NEXT_PUBLIC_SITE_URL + '/api/v2/chat/' + id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...csrfToken,
        },
      });

      if (!response.ok) {
        console.error(JSON.stringify(response, undefined, 2));
        throw new Error('Failed to delete chat');
      }
    },
    onSuccess: () => {
      onSuccess?.();
      return queryClient.invalidateQueries({
        queryKey: ['chat', 'history', assistantId],
      });
    },
  });
}

export function useUpdateChatMutation(
  id: string,
  {
    onMutate,
    onError,
    onSuccess,
    ...options
  }: AdditionalMutationOptions<
    { success: boolean; data: ChatResponse },
    Partial<ChatResponse>,
    ['chat', string, 'web_search']
  > = {},
) {
  const client = useQueryClient();
  const request = useRequest();
  return useMutation({
    ...options,
    mutationKey: ['chat', id, 'web_search'],
    async mutationFn(datum) {
      return request<{ success: boolean; data: ChatResponse }>(`/api/v2/chat/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(datum),
      });
    },
    async onMutate(incoming) {
      await client.ensureQueryData({
        queryKey: ['chat', id],
        async queryFn() {
          return request<ChatResponse>(`/api/v2/chat/${id}`, {
            method: 'GET',
          });
        },
      });
      client.setQueryData<ChatResponse>(['chat', id], (previous) => {
        if (previous) {
          return { ...previous, ...incoming };
        }
      });
      return onMutate?.(incoming);
    },
    async onSuccess(data, enabled, context) {
      await client.invalidateQueries({
        queryKey: ['chat', id],
      });
      return onSuccess?.(data, enabled, context);
    },
    async onError(error, enabled, context) {
      await client.invalidateQueries({
        queryKey: ['chat', id],
      });
      return onError?.(error, enabled, context);
    },
  });
}
