import {
  DefaultError,
  type InfiniteData,
  type UndefinedInitialDataOptions,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
} from '@tanstack/react-query';
import {
  ChatResponse,
  Page_ChatResponse_,
  Page_ChatResponse_ as ChatHistory,
  Page_Message_ as MessagePage,
} from '@portal/chat-sdk';
import { Paginated, request } from '../util';
import { getNextPageParam, getPreviousPageParam } from '../query-client';
import { type AdditionalInfiniteOptions } from '../types';
import { ascend, compose, defaultTo, descend, evolve, lensProp, map, over, prop, sortWith } from 'ramda';
import { useRequest } from '../request';
import { ExpandedMessage } from './mutations';

const getPage = compose<[Paginated<ExpandedMessage>], number | null, number>(defaultTo(1), prop('page'));
const getCreatedAt = compose<[ExpandedMessage], Date | null | undefined, Date>(
  defaultTo(new Date()),
  prop('created_at'),
);
const sortByCreated = sortWith<ExpandedMessage>([descend(getCreatedAt)]);
const sortByPageNumber = sortWith<Paginated<ExpandedMessage>>([ascend(getPage)]);

// Given a list of items in an infinite query, sort them by created_at
export const sortItems = over(
  lensProp<InfiniteData<Paginated<ExpandedMessage>, number>, 'pages'>('pages'),
  map(evolve({ items: sortByCreated })),
);
const sortPages = over(lensProp<InfiniteData<Paginated<ExpandedMessage>, number>, 'pages'>('pages'), sortByPageNumber);

function getNextPage<T>({ page, pages }: Paginated<T>) {
  if (typeof page === 'number') {
    if (typeof pages === 'number') {
      return page + 1 <= pages ? page + 1 : undefined;
    }
    return page + 1;
  }
  return undefined;
}

function getPreviousPage<T>({ page }: Paginated<T>) {
  return typeof page === 'number' && page - 1 > 0 ? page - 1 : undefined;
}

export type ChatMessagesInfiniteQueryOptions<S extends string | null | undefined> = AdditionalInfiniteOptions<
  MessagePage,
  readonly ['chat', S, 'messages']
>;

/**
 * Returns an infinite list of chat messages
 * Note that the query will be disabled if the `chatId` is nullish
 * @example
 * ```typescriptreact
 * function MessageList() {
 *   const { id } = useParams<{ id: string }>();
 *   const { data: { pages } } = useChatMessagesInfiniteQuery(id);
 *   const items = pages?.flatMap((page) => page.items) ?? [];
 *
 *   return (
 *     <ol>
 *       {items.map((item) => (
 *         <li key={item.id}>{item.content}</li>
 *       ))}
 *     </ol>
 *   );
 * }
 * ```
 */
export function useChatMessagesInfiniteQuery(
  id: string | null | undefined,
  options: ChatMessagesInfiniteQueryOptions<string> = {},
) {
  return useInfiniteQuery<
    Paginated<ExpandedMessage>,
    DefaultError,
    InfiniteData<Paginated<ExpandedMessage>, number>,
    readonly ['chat', any, 'messages'],
    number
  >({
    ...options,
    initialPageParam: 1,
    queryKey: ['chat', id, 'messages'],
    enabled: typeof id === 'string' && !id.startsWith('temp-'),
    getNextPageParam: getNextPage,
    getPreviousPageParam: getPreviousPage,
    async queryFn({ pageParam }) {
      return request<Paginated<ExpandedMessage>>({
        pathname: `/api/v2/chat/${id}/messages`,
        query: { page: pageParam, size: 50 },
      });
    },
    select: sortItems,
  });
}

export type ChatQueryOptions = UndefinedInitialDataOptions<ChatResponse>;

/**
 * Returns a chat response
 * @param id
 */
export function useChatQuery(id: string | null | undefined, options?: ChatQueryOptions) {
  return useQuery({
    ...options,
    queryKey: ['chat', id],
    enabled: typeof id === 'string' && !id.startsWith('temp-'),
    async queryFn() {
      return request<ChatResponse>({ pathname: `/api/v2/chat/${id}` });
    },
  });
}

export interface ExtendedInfiniteData extends InfiniteData<ChatHistory, number> {
  isUpdatedBySendMessageMutation?: boolean;
}

/**
 * Returns a paginated list of all chats this user has engaged in
 */
export function useChatHistoryInfiniteQuery(
  assistantId: string | undefined | null,
  title: string = '',
  {
    enabled = true,
    ...options
  }: AdditionalInfiniteOptions<Page_ChatResponse_, ['chat', 'history', string | undefined | null]> = {},
): UseInfiniteQueryResult<ExtendedInfiniteData, Error> {
  const request = useRequest();
  return useInfiniteQuery({
    ...options,
    enabled: !!assistantId && enabled,
    initialPageParam: 1,
    queryKey: ['chat', 'history', assistantId],
    getNextPageParam,
    getPreviousPageParam,
    async queryFn({ pageParam = 1 }) {
      const params = { assistant_id: assistantId!, page: pageParam, title } as { assistant_id: string; title?: string };
      if (title) {
        params.title = title;
      }
      return request<Page_ChatResponse_>('/api/v2/chat', {
        searchParams: params,
      });
    },
  });
}
