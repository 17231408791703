import React, { ComponentPropsWithRef, forwardRef, PropsWithChildren } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../utils';
import { MoveLeft } from '@portal/icons';
import { ActiveMenuItem, useSidebarStore } from '~/stores/my-wandy-sidebar';

export const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 gap-1 from-brand-buttons-gradient-from to-brand-buttons-gradient-to',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-[#f0f0f0] hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        primary:
          'bg-gradient-to-t from-brand-buttons-gradient-from to-brand-buttons-gradient-to text-white font-sans text-sm font-medium px-0 w-full flex-grow hover:from-brand-buttons-hover hover:to-brand-buttons-hover',
        lilac: 'bg-brand-primary-lilac text-white hover:bg-brand-primary-lilac/80',
        gray: 'bg-black-700/40 text-white hover:bg-black-700/40',
        lightblue: 'bg-[#0479FF] text-white hover:bg-[#0479FF]/80',
        gentle: 'bg-brand-nav-tabs text-white hover:bg-brand-nav-tabs/80',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
        primary: 'px-0',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

type BaseProps = PropsWithChildren<{
  asChild?: boolean;
}>;

export type ButtonProps = ComponentPropsWithRef<'button'> & VariantProps<typeof buttonVariants> & BaseProps;

/**
 * Basic button component
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, variant, 'aria-label': ariaLabel, size = 'default', asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
        {children}
      </Comp>
    );
  },
);

Button.displayName = 'Button';

export const ButtonBackMobile = ({
  activeMenuItem,
  onClick,
}: {
  activeMenuItem: ActiveMenuItem;
  onClick?: () => void;
}) => {
  const { setActiveMenuItem } = useSidebarStore();

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={() => (onClick ? onClick() : setActiveMenuItem(activeMenuItem))}
      data-pr="mobileBackBtn"
    >
      <MoveLeft className="-mx-1 w-6 h-6 cursor-pointer font-bold text-lg" />
    </Button>
  );
};
