import { useQuery } from '@tanstack/react-query';
import { request } from '../util';
import { AdditionalQueryOptions } from '../types';
import MembershipRole from '~/lib/organizations/types/membership-role';
import type UserData from '~/core/session/types/user-data';
import type { User } from '@supabase/gotrue-js';

interface Members {
  membershipId: number;
  role: MembershipRole;
  data: UserData;
  plan_id: string | null;
  auth?: User;
}
export type MembersArray = (Members | { auth: User })[];

interface Membership {
  id: number;
  role: MembershipRole;
  plan_id: string | null;
  personal_assistant_id: string;
}

/**
 * Returns all the members for this organization
 */
export function useMembersQuery(options: AdditionalQueryOptions<MembersArray, ['members']> = {}) {
  return useQuery({
    ...options,
    queryKey: ['members'],
    async queryFn() {
      const { data } = await request<{ data: MembersArray }>({
        pathname: `/api/v2/members`,
      });
      return data;
    },
  });
}

/**
 * Returns a single membership for the user
 */
export function useMembershipQuery(userId: string, { ...options }: AdditionalQueryOptions<Membership, ['membership']> = {}) {
  return useQuery({
    ...options,
    queryKey: ['membership'],
    async queryFn() {
      const { data } = await request<{ data: Membership }>({
        pathname: `/api/v2/members/${userId}`,
      });
      return data;
    },
  });
}